import addToMailchimp from "gatsby-plugin-mailchimp"
import React, { useState } from "react"

const EmailListForm = () => {
  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()

    console.log(email)

    addToMailchimp(email)
      .then(data => {
        setSuccess(true)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)
  }

  return (
    <form
      className="px-6 py-8 mt-8 bg-blue-100 rounded"
      onSubmit={handleSubmit}
    >
      <h2 className="text-2xl font-bold text-blue-900">
        Subscribe to my Email{" "}
        <span className="inline transform rotate-180">Newsletter</span>
      </h2>
      <p className="mt-2">
        This is where I send out updates on my newest posts and share
        interesting articles or links I came across that week. Never spam and
        always full of{" "}
        <span className="italic font-bold text-blue-900">
          "Top ten surprising things your dog think about."
        </span>
        {"  "}
      </p>

      <div className="mt-6">
        <input
          className="block w-full px-4 py-3 text-xl text-blue-900 border-2 border-gray-300 border-solid rounded"
          placeholder="Your email address obviously"
          name="email"
          type="text"
          onChange={handleEmailChange}
        />
        {success ? (
          <p className="px-4 py-4 mt-2 font-bold leading-normal text-green-900 bg-green-200 rounded outline-none">
            Successfully signed up for tons of spam emails. Just Kidding.
          </p>
        ) : null}
        <button
          className="px-12 py-3 mt-4 text-xl font-bold leading-normal text-white bg-blue-900 rounded"
          type="submit"
        >
          Subscribe
        </button>
      </div>
    </form>
  )
}

export default EmailListForm
